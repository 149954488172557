/* Home Container */
.home-container {
    display: flex;
    flex-direction: column;
    min-height: 180vh; /* Ensure the container takes up at least the full viewport height */
    color: #fff;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure content spans the full width of the viewport */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Content */
.content {
    position: relative; /* Make sure the container is positioned relatively */
    flex: 1; /* Allow content to expand to fill remaining space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Ensure content is centered */
    padding: 100px 20px; /* Adjust padding for better mobile view */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden; /* Ensure pseudo-element does not overflow */
}

.content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/bg4.jpg') center/cover no-repeat; /* Add background image */
    z-index: -1; /* Ensure the pseudo-element is behind the content */
    opacity: 1; /* Darken the overlay for better contrast */
}

.content h1 {
    font-size: 4em; /* Adjust font size for better readability */
    font-family: "Philosopher", sans-serif;
    margin-bottom: 10px;
    color: #fff; /* Adjust text color */
}

.content p {
    font-size: 1.2em; /* Adjust font size for better readability */
    font-family: "Philosopher", sans-serif;
    margin-bottom: 20px;
    color: #fff; /* Adjust text color */
}

.reservation-button {
    background-color: #ff5500;
    color: #fff;
    padding: 12px 24px; /* Adjust button padding for better appearance */
    font-size: 1.2em;
    font-family: "Philosopher", sans-serif;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s; /* Add smooth transition */
}

.reservation-button:hover {
    background-color: #fff;
    color: #ff5500;
}

/* Footer */
.footer {
    background-color: #091235;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%; /* Ensure footer spans the full width of the viewport */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
