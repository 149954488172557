/* Add keyframes for fade-in animation */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .testimonials-page {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
  }
  
  .testimonials-page h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .testimonial {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .testimonial h3 {
    margin-top: 0;
  }
  
  .testimonial p {
    margin: 5px 0;
  }  