/* FeaturedDishes.css */

.featured-dishes-container {
    text-align: center;
    margin: 50px;
    margin-top: 0;
    padding-bottom: 80px;
    padding-top: 0;
  }
  
  .featured-dishes-title {
    color: #ff5500;
    font-size: 5em;
    font-family: "Philosopher", sans-serif;
    margin-bottom: 20px; /* Add space between title and dishes */
    text-align: left;
  }
  
  .dishes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px; /* Adjust spacing between dishes */
  }
  
  .dish-card {
    background-color: #fff;
    transition: transform 0.3s ease;
    margin: 10px;
  }
  
  .dish-card:hover {
    transform: translateY(-5px); /* Add hover effect */
  }
  
  .dish-image {
    width: 100%;
    height: 200px; /* Adjust image height as needed */
    object-fit: cover;
  }
  
  .dish-details {
    text-align: left;
  }
  
  .dish-name {
    color: #551c00;
    font-size: 1.5em;
    font-family: "Philosopher", sans-serif;
    margin-bottom: 5px; /* Add space between name and description */
    text-align: center;
  }
  
  .dish-description {
    color: #666;
    font-size: 16px;
    font-family: "Philosopher", sans-serif;
    margin-bottom: 10px; /* Add space between description and price */
    text-align: center;
  }

  .menu-button {
    color: #ff5500;
    margin: 20px;
    padding: 15px 30px;
    font-size: 1.5em;
    font-family: "Philosopher", sans-serif;
    border: 1px solid #ff5500; /* Add a border */
    border-radius: 5px; /* Add border radius */
    position: absolute; /* Positioning the button */
    left: 30px; /* Adjust the value as needed */
}

.menu-button:hover {
    background-color: #ff5500;
    color: #fff;
}