.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-image: url('../assets/dish1.2.jpg'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .about-image {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px; /* Space between image and content */
  }
  
  .about-image img {
    width: 90%;
    height: auto;
    object-fit: cover;
    display: block;
    margin: 0 auto;
  }
  
  .about-content {
    max-width: 800px;
    padding: 20px;
    margin: 20px;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.7); /* 0.8 is the opacity level */
    border-radius: 10px;
  }
  
  .about-content h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ff5500;
  }
  
  .about-content p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #fff;
  }
  
  /* Footer */
.footer {
    background-color: #091235;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%; /* Ensure footer spans the full width of the viewport */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
  
  /* Responsive Design */
  @media (min-width: 768px) {
    .about-container {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
    }
  
    .about-image,
    .about-content {
      flex: 1;
      max-width: 50%;
    }
  
    .about-content {
      text-align: left;
      padding-left: 20px;
    }
  }
  