.legal-page-container {
    padding: 20px;
  }
  
  .legal-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .legal-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .legal-section {
    margin-bottom: 30px;
  }
  
  .legal-section h2 {
    margin-bottom: 10px;
  }
  
  .legal-section p {
    line-height: 1.6;
  }
  
  /* Footer */
.footer {
    background-color: #091235;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%; /* Ensure footer spans the full width of the viewport */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}