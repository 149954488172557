/* ContactForm.css */
.contact-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input, textarea {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .input-error {
    border-color: #ff5500;
  }
  
  .error-message {
    color: #ff5500;
    font-size: 14px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px 0;
    background: #ff5500;
    border: #ff5500;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background: #1f9d00;
  }
  
  .success-message-container,
  .error-message-container {
    text-align: center;
    margin: 20px auto;
    padding: 10px;
    max-width: 500px; /* Adjust width as needed */
    border-radius: 5px;
}

.success-message-container {
    background-color: #d4edda;
    color: #155724;
}

.error-message-container {
    background-color: #f8d7da;
    color: #721c24;
}