.star-rating {
    display: inline-block;
  }
  
  .star {
    font-size: 24px; /* Adjust the size of the stars */
  }
  
  .star.filled {
    color: #ffc107; /* Change star color for filled stars */
  }
  
  .star.empty {
    color: #ddd; /* Change star color for empty stars */
  }
  