/* src/styles/global.css */
* {
    font-family: "Philosopher", sans-serif;
}

body {
    background-color: #FFF;
    color: #365486;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  a {
    color: #FFF;
    text-decoration: none;
  }
  
  a:hover {
    color: #00C4FF;
  }
  
  nav {
    background-color: #56504B;
    padding: 1rem;
  }
  
  nav ul {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  nav li {
    margin-right: 1rem;
  }
  
  nav a {
    color: #FFF4D2;
    text-decoration: none;
  }
  
  nav a:hover {
    color: #DD477F;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    nav ul {
      flex-direction: column;
    }
    nav li {
      margin-right: 0;
      margin-bottom: 0.5rem;
    }
  }
  