/* OperatingHoursPage.css */

.operating-hours-page {
    font-family: Arial, sans-serif;
    margin: 80px;
    padding: 80px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.operating-hours-page h1 {
    color: #ff5500;
    margin-bottom: 20px;
}

.hours {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.hours div {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 45%;
}

.hours h2 {
    color: #333;
    margin-bottom: 10px;
    font-size: 25px;
}

.hours p {
    color: #555;
    font-size: 20px;
}

.holiday-hours {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.holiday-hours h2 {
    color: #333;
    margin-bottom: 10px;
}

.holiday-hours p {
    color: #555;
    font-size: 16px;
}

.social-media {
    margin-top: 20px;
}

.social-media h2 {
    color: #ff5500;
    margin-bottom: 10px;
}

.social-icon {
    font-size: 24px;
    color: #ff5500;
    margin: 0 10px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
    transform: scale(1.5);
    color: #091235;
}

 /* Footer */
 .footer {
    background-color: #091235;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%; /* Ensure footer spans the full width of the viewport */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}