/* src/styles/MenuPage.css */
.menu {
    background-color: #091235;
}

.menu-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #091235;
}

.slick-slide img {
    width: 100%;
    height: auto;
    margin-bottom: 20px; /* Add space below the image */
}

.slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
}

h1 {
    margin-bottom: 20px;
    color: #ff5500;
}

/* Custom Arrow Styling */
.slick-prev,
.slick-next {
  font-size: 40px; 
  color: #dd477f !important; 
  z-index: 1;
  background-color: transparent; /* Make background transparent */
  border: none; /* Remove border */
  outline: none; /* Remove outline */
  width: 70px; /* Increase the width of the arrows */
  height: 70px; /* Increase the height of the arrows */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; /* Change cursor to pointer on hover */
  border-radius: 50%; /* Make the arrows circular */
}

.slick-prev::before,
.slick-next::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.slick-prev::before {
  content: "\f053"; /* Use Font Awesome left arrow */
}

.slick-next::before {
  content: "\f054"; /* Use Font Awesome right arrow */
}

.slick-prev {
  left: -80px; /* Adjust the position of the left arrow */
}

.slick-next {
  right: -80px; /* Adjust the position of the right arrow */
}

/* Footer */
.footer {
    background-color: #091235;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 100%; /* Ensure footer spans the full width of the viewport */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Responsive Design */
@media (min-width: 768px) {
    .menu-container {
        max-width: 800px;
        padding: 20px;
    }

    .slick-prev {
        left: -55px;
    }

    .slick-next {
        right: -55px;
    }
}
